import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import {
  TOOLBOX_TALK_MODEL,
  ToolboxTalk,
  ToolboxTalkApiValue,
  ToolboxTalkAttendee,
  ToolboxTalkFormValue,
} from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { box, unbox } from "ngrx-forms";
import { EMPTY, of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { OfflineQueueApiActions } from "../actions";
import { getApiId } from "../model";
import {
  OperationApiActions,
  OperationViewPageActions,
} from "../operations/actions";
import {
  selectDefaultToolboxTalkTemplate,
  selectLoggedUser,
  selectOperation,
  selectOperationSyncOptions,
  selectOperationToolboxTalks,
  selectToolboxTalk,
  selectToolboxTalkForm,
  selectToolboxTalkId,
  selectToolboxTalkTemplate,
} from "../selectors";
import {
  ToolboxTalkApiActions,
  ToolboxTalkListPageActions,
  ToolboxTalkPutPageActions,
  ToolboxTalkViewPageActions,
} from "./actions";

@Injectable()
export class ToolboxTalkEffects {
  private store = inject(Store);
  private router = inject(Router);
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  url = ["/", "toolbox-talks"];

  constructor() {}

  searchOperations$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationApiActions.searchOperationsSuccess),
      map(() => ToolboxTalkListPageActions.searchToolboxTalks())
    )
  );

  searchToolboxTalks$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolboxTalkListPageActions.searchToolboxTalks),
      switchMap(() =>
        this.apiService.search(TOOLBOX_TALK_MODEL).pipe(
          map((apiData) =>
            ToolboxTalkApiActions.searchToolboxTalksSuccess({
              toolboxTalks: apiData.map((item) => new ToolboxTalk(item)),
            })
          ),
          catchError((error) =>
            of(ToolboxTalkApiActions.searchToolboxTalksFailure({ error }))
          )
        )
      )
    )
  );

  openOrCreateToolboxTalk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OperationViewPageActions.openToolboxTalks),
      concatLatestFrom(() => [
        this.store.select(selectLoggedUser),
        this.store.select(selectOperation),
        this.store.select(selectOperationToolboxTalks),
        this.store.select(selectDefaultToolboxTalkTemplate),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, user, operation, toolboxTalks, template, sync]) => {
        if (toolboxTalks.length > 1) {
          return of(
            ToolboxTalkApiActions.listToolboxTalks({
              operationId: getApiId(operation),
            })
          );
        }
        if (toolboxTalks.length == 1) {
          return of(
            ToolboxTalkApiActions.viewToolboxTalk({
              toolboxTalk: toolboxTalks[0],
            })
          );
        }
        if (toolboxTalks.length == 0) {
          const values = new ToolboxTalkApiValue({
            operation_id: getApiId(operation),
            template_id: box(template),
            speaker_name: user.name,
            speaker_position: "Supervisor",
            item_ids: template.item_ids,
            attendee_ids: operation.diver_ids.map(
              (diver) =>
                new ToolboxTalkAttendee({
                  name: diver.name,
                  position: "Diver",
                })
            ),
          });
          return this.apiService.create(TOOLBOX_TALK_MODEL, values, sync).pipe(
            map((apiData) =>
              ToolboxTalkApiActions.createToolboxTalkSuccess({
                toolboxTalk: new ToolboxTalk(apiData),
              })
            ),
            catchError((error) =>
              of(ToolboxTalkApiActions.createToolboxTalkFailure({ error }))
            )
          );
        }
        return EMPTY;
      })
    )
  );

  openToolboxTalkListPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToolboxTalkApiActions.listToolboxTalks),
        tap(({ operationId }) =>
          this.router.navigate([...this.url, "operation", operationId])
        )
      ),
    { dispatch: false }
  );

  openToolboxTalkViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ToolboxTalkApiActions.viewToolboxTalk,
          ToolboxTalkApiActions.createToolboxTalkSuccess,
          ToolboxTalkApiActions.updateToolboxTalkSuccess
        ),
        concatLatestFrom(() => this.store.select(selectToolboxTalkId)),
        tap(([{ type }, toolboxTalkId]) => {
          this.router.navigate([...this.url, "view", toolboxTalkId], {
            replaceUrl:
              type == ToolboxTalkApiActions.updateToolboxTalkSuccess.type,
          });
        })
      ),
    { dispatch: false }
  );

  openToolboxTalkEditPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ToolboxTalkViewPageActions.updateToolboxTalk),
        concatLatestFrom(() => this.store.select(selectToolboxTalkId)),
        tap(([, toolboxTalkId]) => {
          this.router.navigate([...this.url, "edit", toolboxTalkId]);
        })
      ),
    { dispatch: false }
  );

  setOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ToolboxTalkViewPageActions.setToolboxTalk,
        ToolboxTalkPutPageActions.setToolboxTalk
      ),
      concatLatestFrom(() => this.store.select(selectToolboxTalk)),
      map(([, { operation_id: operationId }]) =>
        ToolboxTalkApiActions.setOperation({ operationId })
      )
    )
  );

  setToolboxTalkTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolboxTalkPutPageActions.setTemplate),
      concatLatestFrom(() => [
        this.store.select(selectToolboxTalkForm),
        this.store.select(selectToolboxTalkTemplate),
      ]),
      map(([, form, template]) =>
        ToolboxTalkApiActions.setToolboxTalkTemplate({
          toolboxTalkFormValue: new ToolboxTalkFormValue({
            ...unbox(form.value),
            item_ids: template.item_ids,
          }),
        })
      )
    )
  );

  setToolboxTalkForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolboxTalkPutPageActions.setToolboxTalk),
      concatLatestFrom(() => this.store.select(selectToolboxTalk)),
      map(([, toolboxTalk]) =>
        ToolboxTalkApiActions.setToolboxTalkForm({
          toolboxTalkFormValue: new ToolboxTalkFormValue(toolboxTalk),
        })
      )
    )
  );

  updateToolboxTalk$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ToolboxTalkPutPageActions.updateToolboxTalk),
      concatLatestFrom(() => [
        this.store.select(selectToolboxTalk),
        this.store.select(selectToolboxTalkForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, toolboxTalk, form, sync]) => {
        if (!form.isValid) {
          return of(ToolboxTalkApiActions.toolboxTalkFormInvalid());
        }
        const values = new ToolboxTalkApiValue(form.value);
        return this.apiService
          .update(TOOLBOX_TALK_MODEL, toolboxTalk._id, values, sync)
          .pipe(
            map((apiData) =>
              ToolboxTalkApiActions.updateToolboxTalkSuccess({
                toolboxTalk: new ToolboxTalk(apiData),
              })
            ),
            catchError((error) =>
              of(ToolboxTalkApiActions.updateToolboxTalkFailure({ error }))
            )
          );
      })
    )
  );

  syncToolboxTalkSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter(({ model }) => model == TOOLBOX_TALK_MODEL),
      map(({ data }) =>
        ToolboxTalkApiActions.syncToolboxTalkSuccess({
          toolboxTalk: new ToolboxTalk(data),
        })
      )
    )
  );
}
