import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import {
  CHECKLIST_LINE_MODEL,
  ChecklistLine,
  ChecklistLineApiValue,
  ChecklistLineFormValue,
} from "@app/models";
import {
  selectChecklist,
  selectChecklistDefaultTemplate,
  selectChecklistId,
  selectChecklistLine,
  selectChecklistLineForm,
  selectChecklistLineId,
  selectChecklistTemplate,
  selectOperationSyncOptions,
} from "@app/state/selectors";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  ChecklistApiActions,
  ChecklistViewPageActions,
  OfflineQueueApiActions,
} from "../actions";
import {
  ChecklistLineApiActions,
  ChecklistLinePutPageActions,
  ChecklistLineViewPageActions,
} from "./actions";
import { unbox } from "ngrx-forms";

@Injectable()
export class ChecklistLineEffects {
  private store = inject(Store);
  private router = inject(Router);
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  url = ["/", "checklists", "line"];

  constructor() {}

  searchChecklists$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistApiActions.searchChecklistsSuccess),
      map(() => ChecklistLineApiActions.searchChecklistLines({}))
    )
  );

  searchCheckListLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistLineApiActions.searchChecklistLines),
      switchMap(() =>
        this.apiService.search(CHECKLIST_LINE_MODEL).pipe(
          map((apiData) =>
            ChecklistLineApiActions.searchChecklistLinesSuccess({
              checklistLines: apiData.map((item) => new ChecklistLine(item)),
            })
          ),
          catchError((error) =>
            of(ChecklistLineApiActions.searchChecklistLinesFailure({ error }))
          )
        )
      )
    )
  );

  openChecklistLineCreatePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChecklistViewPageActions.createChecklistLine),
        concatLatestFrom(() => this.store.select(selectChecklistId)),
        tap(([, checklistId]) =>
          this.router.navigate([...this.url, "create", checklistId])
        )
      ),
    { dispatch: false }
  );

  openChecklistLineViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChecklistViewPageActions.viewChecklistLine),
        tap(({ checklistLineId }) =>
          this.router.navigate([...this.url, "view", checklistLineId])
        )
      ),
    { dispatch: false }
  );

  openChecklistLineEditPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ChecklistViewPageActions.updateChecklistLine),
        concatLatestFrom(() => this.store.select(selectChecklistLineId)),
        tap(([, checklistLineId]) =>
          this.router.navigate([...this.url, "edit", checklistLineId])
        )
      ),
    { dispatch: false }
  );

  setChecklist$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ChecklistLineViewPageActions.setChecklistLine,
        ChecklistLinePutPageActions.setChecklistLine
      ),
      concatLatestFrom(() => this.store.select(selectChecklistLine)),
      map(([, { checklist_id: checklistId }]) =>
        ChecklistLineApiActions.setChecklist({ checklistId })
      )
    )
  );

  setOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistLineApiActions.setChecklist),
      concatLatestFrom(() => this.store.select(selectChecklist)),
      map(([, { operation_id: operationId }]) =>
        ChecklistLineApiActions.setOperation({ operationId })
      )
    )
  );

  setDefaultChecklistLineForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistLinePutPageActions.setChecklist),
      concatLatestFrom(() => [
        this.store.select(selectChecklistId),
        this.store.select(selectChecklistDefaultTemplate),
      ]),
      map(([, checklistId, template]) =>
        ChecklistLineApiActions.setChecklistLineForm({
          checklistLineFormValue: new ChecklistLineFormValue({
            name: template.name,
            checklist_id: checklistId,
            template_id: template,
            item_ids: template.item_ids,
          }),
        })
      )
    )
  );

  setChecklistLineForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistLinePutPageActions.setChecklistLine),
      concatLatestFrom(() => this.store.select(selectChecklistLine)),
      map(([, checklistLine]) =>
        ChecklistLineApiActions.setChecklistLineForm({
          checklistLineFormValue: new ChecklistLineFormValue(checklistLine),
        })
      )
    )
  );

  setChecklistLineTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistLinePutPageActions.setTemplate),
      concatLatestFrom(() => [
        this.store.select(selectChecklistLineForm),
        this.store.select(selectChecklistTemplate),
      ]),
      map(([, form, template]) =>
        ChecklistLineApiActions.setChecklistLineTemplate({
          checklistLineFormValue: new ChecklistLineFormValue({
            ...unbox(form.value),
            name: template.name,
            item_ids: template.item_ids,
          }),
        })
      )
    )
  );

  createCheckListLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistLinePutPageActions.createChecklistLine),
      concatLatestFrom(() => [
        this.store.select(selectChecklistLineForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, form, sync]) => {
        if (!form.isValid) {
          return of(ChecklistLineApiActions.checkListLineFormInvalid());
        }
        const values = new ChecklistLineApiValue(form.value);
        return this.apiService.create(CHECKLIST_LINE_MODEL, values, sync).pipe(
          map((apiData) =>
            ChecklistLineApiActions.createChecklistLineSuccess({
              checklistLine: new ChecklistLine(apiData),
            })
          ),
          catchError((error) =>
            of(ChecklistLineApiActions.createChecklistLineFailure({ error }))
          )
        );
      })
    )
  );

  updateChecklistLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ChecklistLinePutPageActions.updateChecklistLine),
      concatLatestFrom(() => [
        this.store.select(selectChecklistLineId),
        this.store.select(selectChecklistLineForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, checklistLineId, form, sync]) => {
        if (!form.isValid) {
          return of(ChecklistLineApiActions.checkListLineFormInvalid());
        }
        const values = new ChecklistLineApiValue(form.value);
        return this.apiService
          .update(CHECKLIST_LINE_MODEL, checklistLineId, values, sync)
          .pipe(
            map((apiData) =>
              ChecklistLineApiActions.updateChecklistLineSuccess({
                checklistLine: new ChecklistLine(apiData),
              })
            ),
            catchError((error) =>
              of(ChecklistLineApiActions.updateChecklistLineFailure({ error }))
            )
          );
      })
    )
  );

  syncChecklistLineSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter(({ model }) => model == CHECKLIST_LINE_MODEL),
      map(({ data }) =>
        ChecklistLineApiActions.syncChecklistLineSuccess({
          checklistLine: new ChecklistLine(data),
        })
      )
    )
  );
}
