import { inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import {
  DIVE_LOG_LINE_MODEL,
  DiveLogLine,
  DiveLogLineApiValue,
  DiveLogLineFormValue,
  Timesheet,
} from "@app/models";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import {
  catchError,
  exhaustMap,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import {
  DiveLogApiActions,
  DiveLogViewPageActions,
  OfflineQueueApiActions,
} from "../actions";
import {
  selectDefaultDiveLogTemplate,
  selectDiveLog,
  selectDiveLogId,
  selectDiveLogLine,
  selectDiveLogLineForm,
  selectDiveLogLineId,
  selectDiveLogTemplate,
  selectOperationId,
  selectOperationSyncOptions,
} from "../selectors";
import {
  DiveLogLineApiActions,
  DiveLogLinePutPageActions,
  DiveLogLineViewPageActions,
} from "./actions";
import { unbox } from "ngrx-forms";

@Injectable()
export class DiveLogLineEffects {
  private store = inject(Store);
  private router = inject(Router);
  private apiService = inject(ApiService);
  private actions$ = inject(Actions);

  url = ["/", "dive-logs", "line"];

  constructor() {}

  searchDiveLogs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogApiActions.searchDiveLogsSuccess),
      map(() => DiveLogLineApiActions.searchDiveLogLines())
    )
  );

  searchDiveLogLines$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLineApiActions.searchDiveLogLines),
      switchMap(() =>
        this.apiService.search(DIVE_LOG_LINE_MODEL).pipe(
          map((apiData) =>
            DiveLogLineApiActions.searchDiveLogLinesSuccess({
              diveLogLines: apiData.map((item) => new DiveLogLine(item)),
            })
          ),
          catchError((error) =>
            of(DiveLogLineApiActions.searchDiveLogLinesFailure({ error }))
          )
        )
      )
    )
  );

  openDiveLogLineCreatePage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiveLogViewPageActions.createDiveLogLine),
        concatLatestFrom(() => this.store.select(selectDiveLogId)),
        tap(([, diveLogId]) =>
          this.router.navigate([...this.url, "create", diveLogId])
        )
      ),
    { dispatch: false }
  );

  openDiveLogLineViewPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiveLogViewPageActions.viewDiveLogLine),
        tap(({ diveLogLineId }) =>
          this.router.navigate([...this.url, "view", diveLogLineId])
        )
      ),
    { dispatch: false }
  );

  openDiveLogLineEditPage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DiveLogLineViewPageActions.updateDiveLogLine),
        concatLatestFrom(() => this.store.select(selectDiveLogLineId)),
        tap(([, logLineId]) => {
          this.router.navigate([...this.url, "edit", logLineId]);
        })
      ),
    { dispatch: false }
  );

  setDiveLog$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DiveLogLineViewPageActions.setDiveLogLine,
        DiveLogLinePutPageActions.setDiveLogLine
      ),
      concatLatestFrom(() => this.store.select(selectDiveLogLine)),
      map(([, { log_id: logId }]) =>
        DiveLogLineApiActions.setDiveLog({ logId })
      )
    )
  );

  setOperation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLineApiActions.setDiveLog),
      concatLatestFrom(() => this.store.select(selectDiveLog)),
      map(([, { operation_id: operationId }]) =>
        DiveLogLineApiActions.setOperation({ operationId })
      )
    )
  );

  setDefaultDiveLogLineForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLinePutPageActions.setDiveLog),
      concatLatestFrom(() => [
        this.store.select(selectDiveLogId),
        this.store.select(selectDefaultDiveLogTemplate),
      ]),
      map(([, diveLogId, template]) =>
        DiveLogLineApiActions.setDiveLogLineForm({
          diveLogLineFormValue: new DiveLogLineFormValue({
            log_id: diveLogId,
            template_id: template,
            item_ids: template.item_ids,
          }),
        })
      )
    )
  );

  setDiveLogLineForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLinePutPageActions.setDiveLogLine),
      concatLatestFrom(() => this.store.select(selectDiveLogLine)),
      map(([, diveLogLine]) =>
        DiveLogLineApiActions.setDiveLogLineForm({
          diveLogLineFormValue: new DiveLogLineFormValue(diveLogLine),
        })
      )
    )
  );

  setDiveLogLineTemplate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLinePutPageActions.setTemplate),
      concatLatestFrom(() => [
        this.store.select(selectDiveLogLineForm),
        this.store.select(selectDiveLogTemplate),
      ]),
      map(([, form, template]) =>
        DiveLogLineApiActions.setDiveLogLineTemplate({
          diveLogLineFormValue: new DiveLogLineFormValue({
            ...unbox(form.value),
            item_ids: template.item_ids,
          }),
        })
      )
    )
  );

  addTimesheetControl$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLinePutPageActions.addTimesheet),
      concatLatestFrom(() => this.store.select(selectOperationId)),
      map(([, operation_id]) =>
        DiveLogLineApiActions.addTimesheetControl({
          timesheet: new Timesheet({ operation_id }),
        })
      )
    )
  );

  createDiveLogLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLinePutPageActions.createDiveLogLine),
      concatLatestFrom(() => [
        this.store.select(selectDiveLogLineForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, form, sync]) => {
        if (!form.isValid) {
          return of(DiveLogLineApiActions.diveLogLineFormInvalid());
        }
        const values = new DiveLogLineApiValue(form.value);
        return this.apiService.create(DIVE_LOG_LINE_MODEL, values, sync).pipe(
          map((apiData) =>
            DiveLogLineApiActions.createDiveLogLineSuccess({
              diveLogLine: new DiveLogLine(apiData),
            })
          ),
          catchError((error) =>
            of(DiveLogLineApiActions.createDiveLogLineFailure({ error }))
          )
        );
      })
    )
  );

  updateDiveLogLine$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DiveLogLinePutPageActions.updateDiveLogLine),
      concatLatestFrom(() => [
        this.store.select(selectDiveLogLineId),
        this.store.select(selectDiveLogLineForm),
        this.store.select(selectOperationSyncOptions),
      ]),
      exhaustMap(([, logLineId, form, sync]) => {
        if (!form.isValid) {
          return of(DiveLogLineApiActions.diveLogLineFormInvalid());
        }
        const values = new DiveLogLineApiValue(form.value);
        return this.apiService
          .update(DIVE_LOG_LINE_MODEL, logLineId, values, sync)
          .pipe(
            map((apiData) =>
              DiveLogLineApiActions.updateDiveLogLineSuccess({
                diveLogLine: new DiveLogLine(apiData),
              })
            ),
            catchError((error) =>
              of(DiveLogLineApiActions.updateDiveLogLineFailure({ error }))
            )
          );
      })
    )
  );

  syncDiveLogLineSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        OfflineQueueApiActions.ApiCreateSuccess,
        OfflineQueueApiActions.ApiUpdateSuccess
      ),
      filter((action) => action.model == DIVE_LOG_LINE_MODEL),
      map((action) =>
        DiveLogLineApiActions.syncDiveLogLineSuccess({
          diveLogLine: new DiveLogLine(action.data),
        })
      )
    )
  );
}
